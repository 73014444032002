@import 'globals';

@include react-md-everything;

// Custom styles
@import "custom";

// Material things
body {
  margin: 0;
}

.aos {
  background: $md-orange-500;
  color: #ffffff;
}

.code-block {
  background: rgba(0, 0, 0, .06);
  overflow-x: auto;
  padding: 16px;
}

.buttons__group {
  margin: 1em;
}

// Upload
.file-inputs__upload-form {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  &__progress {
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__file-upload {
    flex-shrink: 0;
  }

  &__file-field {
    flex-grow: 1;
    margin-left: $md-default-padding / 2;
  }
}

.footer {
  background: #424242;
  color: #fff;
}

.footer .md-body-2, .footer .md-title, .footer p {
  color: inherit;
}

#rotating {
  animation: roll 3s infinite;
}

@keyframes roll {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
